var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-12" },
      [
        _c("c-tab", {
          attrs: { type: "tabcard", tabItems: _vm.tabItems, inlineLabel: true },
          on: {
            "update:tabItems": function ($event) {
              _vm.tabItems = $event
            },
            "update:tab-items": function ($event) {
              _vm.tabItems = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [_c(tab.component, { tag: "component" })]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }