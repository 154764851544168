<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'inout-statisical-investigation-hardcoding',
  props: {
  },
  data() {
    return {
      tab: 'statistics',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        // 제조/수입/사용/판매 관리대장
        { name: 'statistics', icon: 'logout', label: '제조/수입/사용/판매 관리대장', component: () => import(`${'./inoutManagement.vue'}`) },
        // // 보관/저장 관리대장
        // { name: 'emissions', icon: 'savings', label: '보관/저장 관리대장', component: () => import(`${'./saveManagement.vue'}`) },
        // // 운반 관리대장
        // { name: 'emissions', icon: 'local_shipping', label: '운반 관리대장', component: () => import(`${'./driveManagement.vue'}`) },
        // // 외부인 및 유해화학물질 대리 참관자 출입 관리대장
        // { name: 'emissions', icon: 'face', label: '외부인 및 유해화학물질 대리 참관자 출입 관리대장', component: () => import(`${'./deputyInoutManagement.vue'}`) },
      ]
    },

  }
};
</script>